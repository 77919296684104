import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HeroSection } from '@core/models/hero-section.model';
import { HomePageSlide } from '@core/models/homepage-slide.model';
import { MediaType } from '@core/models/media-info.model';
import { RaffleSimple } from '@core/models/raffle.model';
import { WinnerCms } from '@core/models/winner.model';
import { GonativeService } from '@core/services/gonative/gonative.service';
import { API_LIST } from '@core/services/web-api/api-list';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { ItemTab } from '../components/raffles-nav/raffles-nav.component';
import { TrustpilotConfig, TrustpilotTemplate, TrustpilotTheme } from '@core/models/trustpilot.model';


class CompetitionSection {
  rafflesLoader$: Observable<RaffleSimple[]> | null = null;
  raffles: RaffleSimple[] = [];
  menuName = 'Competitions';
  menuFullName = 'Competitions';
  headingName = 'Competitions';
  idAttr = '';
  href = '';
  isLoading = true;
  isAvailable = true;
  isOdd = false;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  static CACHED_HERO_SECTION: HeroSection | null = null;

  slides: HomePageSlide[] = [];
  winners: { url: string, title: string, name: string }[] = [];
  competitionSections: CompetitionSection[] = [];
  isLoadingNavigation = true;
  rafflesNavItems: ItemTab[] = [];
  selectedRafflesItem: ItemTab;
  isNativeAppVisible = false;
  headerIsShort: boolean;

  trustpilotConfig: TrustpilotConfig = {
    template: TrustpilotTemplate.Free,
    theme: TrustpilotTheme.Light
  };

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.initSlider();
    this.initCompetitions();
    this.initWinners();
    this.isNativeAppVisible = GonativeService.isGonative();
  }

  initSlider(): void {
    if (HomeComponent.CACHED_HERO_SECTION) {
      this.applyHeroSection(HomeComponent.CACHED_HERO_SECTION);
    } else {
      this.httpClient.get<HeroSection>(API_LIST.getHeroSection('homepage')).subscribe((data: HeroSection) => {
        HomeComponent.CACHED_HERO_SECTION = data;
        this.applyHeroSection(HomeComponent.CACHED_HERO_SECTION);
      });
    }
  }

  applyHeroSection(data: HeroSection): void {
    if (data.boxes.length > 0) {
      const slides: HomePageSlide[] = [];

      for (const box of data.boxes) {
        const slide = new HomePageSlide();
        slide.title = box.title;
        slide.line1 = box.line1;
        slide.line2 = box.line2;
        slide.sourceUrl = box.media_info.url;
        slide.poster = box.media_info.original;
        slide.type = box.media_info.type;
        slide.ctaUrl = box.url;
        slide.ctaTitle = 'Enter Now';
        slides.push(slide);
      }

      this.slides = slides;
    } else {
      this.initDefaultSlider();
    }


  }

  initDefaultSlider(): void {
    this.slides = [
      {
        title: 'WIN AMAZING PRIZES TODAY!',
        line1: 'WIN AMAZING',
        line2: 'PRIZES TODAY!',
        sourceUrl: 'assets/images/ukcarp/slider/moneybag.jpg',
        type: MediaType.Image,
        ctaUrl: '/competitions',
      }
    ];
  }

  initCompetitions(): void {
    this.addCompetitionSection(this.httpClient.get<RaffleSimple[]>(
      API_LIST.getRaffleDraws('GBP', ['biggest-competitions'], 20, false, true, 0, 'value-desc')
    ), 'Biggest Comps', 'Biggest Competitions', 'Biggest Competitions', 'biggest-competitions', 'competitions');

    this.addCompetitionSection(this.httpClient.get<RaffleSimple[]>(
      API_LIST.getRaffleDraws('GBP', [], 20, false, true, 0, 'date')
    ), 'Ending Soon', 'Ending Soonest', 'Ending Soonest', 'ending-soon-competitions', 'ending-soon');
    
    this.addCompetitionSection(this.httpClient.get<RaffleSimple[]>(
      API_LIST.getRaffleDraws('GBP', ['instant-wins'], 20, false, true, 0, 'date')
    ), 'Instant Wins', 'Instant Wins', 'Instant Wins', 'instant-wins-competitions', 'instant-wins');

    this.addCompetitionSection(this.httpClient.get<RaffleSimple[]>(
      API_LIST.getRaffleDraws('GBP', ['fishing'], 20, false, true, 0, 'date')
    ), 'Fishing', 'Fishing', 'Fishing', 'fishing-competitions', 'fishing');

    this.addCompetitionSection(this.httpClient.get<RaffleSimple[]>(
      API_LIST.getRaffleDraws('GBP', ['tech-and-cash'], 20, false, true, 0, 'date')
    ), 'Tech & Cash', 'Tech & Cash', 'Tech & Cash', 'tech-and-cash-competitions', 'tech-and-cash');


  }

  addCompetitionSection(rafflesLoader$: Observable<RaffleSimple[]>, menuName: string, menuFullName: string, headingName: string, idAttr: string, href: string): void {
    const section = new CompetitionSection();

    const loaderShared$ = rafflesLoader$.pipe(share());

    loaderShared$.subscribe((raffles: RaffleSimple[]) => {
      section.raffles = raffles;
      section.isAvailable = (raffles.length > 0);
      section.isLoading = false;
      this.refreshCompetitionSectionsOrder();
    }, () => {
      section.isAvailable = false;
      section.isLoading = false;
      this.refreshCompetitionSectionsOrder();
    });

    section.rafflesLoader$ = loaderShared$;
    section.menuName = menuName;
    section.menuFullName = menuFullName;
    section.headingName = headingName;
    section.idAttr = idAttr;
    if (href) {
      section.href = href;

    }

    this.competitionSections.push(section);
  }

  refreshCompetitionSectionsOrder(): void {
    let lastOdd = true;
    let isLoadingAnySection = false;

    for (const section of this.competitionSections) {
      if (section.isLoading) {
        isLoadingAnySection = true;
      }

      if (!section.isLoading && !section.isAvailable) {
        continue;
      }

      section.isOdd = lastOdd;
      lastOdd = !lastOdd;
    }

    this.isLoadingNavigation = isLoadingAnySection;

    if (!isLoadingAnySection) {
      this.buildRafflesNavItems();
    }
  }

  buildRafflesNavItems(): void {
    const items: ItemTab[] = [];

    for (const section of this.competitionSections) {
      if (!section.isAvailable) {
        continue;
      }

      const itemTab = new ItemTab();
      itemTab.idAttr = section.idAttr;
      itemTab.name = section.menuName;
      itemTab.fullName = section.menuFullName;

      items.push(itemTab);
    }

    this.rafflesNavItems = items;
    this.selectedRafflesItem = items[0];
  }

  initWinners(): void {
    this.httpClient
      .get<WinnerCms[]>(API_LIST.getDrawWinners(0, 12))
      .pipe(map(winners => winners.map(winner => ({
        url: winner.image_info.big,
        title: winner.title,
        name: `${winner.first_name} ${winner.last_name}`
      }))))
      .subscribe({
        next: (winners: { url: string, title: string, name: string }[]) => {
          this.winners = winners;
        },
        error: () => {
          // TODO: add error checking
        }
      });
  }

  changeHeaderState(isShort: boolean): void {
    this.headerIsShort = isShort;
  }


  trackBy(_, item: RaffleSimple): number {
    return item.id;
  }
}
