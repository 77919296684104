<a [routerLink]="['/competition', raffle.slug]" class="raffle-card">
  <app-raffle-card-badge
    *ngIf="!raffle.is_without_draw"
    [resultAt]="raffle.result_at"
    [endAt]="raffle.end_at"
    [isOpen]="raffle.is_open"
  >
  </app-raffle-card-badge>

  <div class="raffle-card__image">
    <div class="raffle-card__image-red-cover" *ngIf="!raffle.is_open">
      <ng-container
        *ngIf="
          raffle.current_entries >= raffle.max_entries;
          else competitionClosed
        "
        >Sold out!</ng-container
      >
      <ng-template #competitionClosed>Closed</ng-template>
    </div>

    <img
      [src]="raffle.thumbnail.big"
      [alt]="raffle.thumbnail.attributes.alt"
      [attr.title]="
        raffle.thumbnail.attributes.title
          ? raffle.thumbnail.attributes.title
          : raffle.title
      "
    />
    <div class="raffle-card__bottominfo" *ngIf="bottomInfoText">{{
      bottomInfoText
    }}</div>
  </div>

  <app-countdown [expiredAt]="raffle?.end_at"></app-countdown>

  <div class="raffle-card__logo" *ngIf="isInstantWin">
    <app-raffle-instant-logo
      [instantType]="raffle.instant_win_type"
    ></app-raffle-instant-logo>
  </div>

  <div
    class="raffle-card__wrapper"
    [class.raffle-card__wrapper-margin]="!isInstantWin"
  >
    <h3 class="raffle-card__title">
      {{ raffle.title }}
    </h3>
    <div class="raffle-card__subtitle" *ngIf="raffle.subtitle">
      {{ raffle.subtitle }}
    </div>
    <div *ngIf="raffle.cash_alternative" class="raffle-card__alternative">
      Cash Alternative:
      {{ raffle.cash_alternative | currency: 'GBP' : 'symbol' : '1.0' }}
    </div>
  </div>

  <div class="raffle-card__price">
    <span class="raffle-card__price-title">Per entry</span>
    <span class="raffle-card__price-old" *ngIf="originalPrice">{{
      originalPrice | currency: currency
    }}</span>
    <span class="raffle-card__price-new">{{ price | currency: currency }}</span>
  </div>

  <div
    class="raffle-card__slider"
    [class.raffle-card__slider--hidden]="raffle.is_cash || sliderValue === null"
  >
    <div class="raffle-card__sold">
      <span class="raffle-card__sold-title">SOLD: </span>
      <span class="raffle-card__sold-value">{{ sliderValue }}%</span>
    </div>

    <div class="raffle-card__slider--track">
      <span
        class="raffle-card__slider--track-fill"
        [ngStyle]="{ width: sliderValue + '%' }"
      ></span>
    </div>
  </div>

  <div class="button-wrapper">
    <button type="button" class="button-new button-new__primary">
      Enter now
      <svg-icon name="arrow-filled"></svg-icon>
    </button>
  </div>
</a>
